import styled, { css } from "styled-components"
import { BoxProps } from "app/components/Box"

export type StyledBoxProps = Pick<BoxProps, "variant">

const StyledCTABoxCSS = css`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`

const StyledDefaultBoxCSS = css`
  border: 1px solid ${({ theme }) => theme.colors.gray_light};
`

export const StyledBox = styled.div<StyledBoxProps>`
  white-space: pre-line;
  padding: ${({ theme }) => theme.space[2]};

  @media ${({ theme }) => theme.media.md_up} {
    padding: ${({ theme }) => theme.space[3]};
  }

  ${({ variant }) => {
    switch (variant) {
      case "cta":
        return StyledCTABoxCSS
      default:
        return StyledDefaultBoxCSS
    }
  }};

  > :last-child {
    margin-bottom: 0;
  }
`

export const StyledBottomBox = styled(StyledBox)`
  margin-top: auto;

  @media ${({ theme }) => theme.media.md_down} {
    border: 0;
    padding: ${({ theme }) => theme.space[2]} 0;
  }
`
