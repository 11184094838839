import { useTranslate } from "hooks/translate"
import Box from "app/components/Box"
import Heading from "app/components/Heading"
import Space from "app/components/Space"
import { getEnvVariable } from "utils"

const SPECIAL_OFFER_POLESTAR_2 = getEnvVariable(
  "REACT_APP_SPECIAL_OFFER_POLESTAR_2"
)
const SPECIAL_OFFER_POLESTAR_3 = getEnvVariable(
  "REACT_APP_SPECIAL_OFFER_POLESTAR_3"
)
const SPECIAL_OFFER_POLESTAR_4 = getEnvVariable(
  "REACT_APP_SPECIAL_OFFER_POLESTAR_4"
)

const SpecialOffer: React.FC = () => {
  const { t } = useTranslate()

  return (
    <Space bottom={5}>
      <Box variant="cta">
        <Heading as="h2">
          {t("form.step_5.cta.heading", {
            special_offer_p2: SPECIAL_OFFER_POLESTAR_2,
            special_offer_p3: SPECIAL_OFFER_POLESTAR_3,
            special_offer_p4: SPECIAL_OFFER_POLESTAR_4
          })}
        </Heading>
        <p>
          {t("form.step_5.cta.body", {
            special_offer_p2: SPECIAL_OFFER_POLESTAR_2,
            special_offer_p3: SPECIAL_OFFER_POLESTAR_3,
            special_offer_p4: SPECIAL_OFFER_POLESTAR_4
          })}
        </p>
      </Box>
    </Space>
  )
}

export default SpecialOffer
