import { StyledBox } from "./style"

export interface BoxProps {
  variant?: "cta"
}

const Box: React.FC<BoxProps> = ({ variant, children }) => {
  return <StyledBox variant={variant}>{children}</StyledBox>
}

export default Box
