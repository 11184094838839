import styled, { css } from "styled-components"
import { ButtonProps } from "app/components/Button"
import React from "react"
import { Icon } from "../Icon"

export type StyledButtonProps = Pick<ButtonProps, "variant" | "component">
export type StyledButtonIconProps = Pick<ButtonProps, "iconPlacement">

export const ButtonContainedCSS = css`
  transition: background 200ms ease;
  padding: 1.2rem ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    background: ${({ theme }) => theme.colors.gray_dark};
    color: ${({ theme }) => theme.colors.white};
  }
`
export const ButtonTextCSS = css`
  transition: color 200ms ease;
  padding: 0;
  margin: 1.2rem 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    color: ${({ theme }) => theme.colors.gray_light};
  }
`

export const ButtonPlainCSS = css`
  ${ButtonTextCSS};
  margin: 0;
`

const ButtonCSS = css<StyledButtonProps>`
  cursor: pointer;
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  border: none;
  letter-spacing: 0.1rem;
  line-height: inherit;
  text-decoration: none;
  ${({ variant }) => {
    switch (variant) {
      case "text":
        return ButtonTextCSS
      case "plain":
        return ButtonPlainCSS
      default:
        return ButtonContainedCSS
    }
  }}
`

export const StyledButton = styled(({ component, ...props }) =>
  component
    ? React.cloneElement(component, props)
    : React.createElement("button", props)
)<StyledButtonProps>`
  ${ButtonCSS};
`

export const StyledButtonIcon = styled(Icon)<StyledButtonIconProps>`
  color: ${({ theme }) => theme.colors.primary};
  ${({ iconPlacement, theme }) => {
    switch (iconPlacement) {
      case "postfix":
        return `margin-left: ${theme.space[1]}`
      default:
        return `margin-right: ${theme.space[1]}`
    }
  }}
`
