import React, { HTMLInputTypeAttribute, useState } from "react"
import Field, { BaseFieldProps } from "app/components/Form/Field"
import { DefaultInputProps } from "../types"
import { StyledInput } from "./style"

export interface InputFieldProps extends BaseFieldProps, DefaultInputProps {
  type?: HTMLInputTypeAttribute
}

const InputField: React.FC<InputFieldProps> = props => {
  const { label, ...rest } = props
  const { name, type } = rest

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  return (
    <Field
      {...props}
      label={label}
      isFocused={isFocused}
      render={({ field: { onChange, onBlur, value } }) => (
        <StyledInput
          {...rest}
          id={name}
          type={type}
          value={value as string | number | undefined}
          onChange={onChange}
          onBlur={() => {
            onBlur()
            handleBlur()
          }}
          onFocus={handleFocus}
        />
      )}
    />
  )
}

export default InputField
