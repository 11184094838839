import React from "react"
import { useTranslate } from "hooks/translate"
import Heading from "app/components/Heading"
import Copyright from "app/components/Copyright"
import {
  StyledContainer,
  StyledLayout,
  StyledLayoutAside,
  StyledLayoutAsideBody,
  StyledLayoutMain,
  StyledLayoutSection,
  StyledFooter,
  StyledDivider,
  StyledFooterContent,
  StyledHeader,
  StyledLogoWrapper,
  StyledLogo,
  StyledKBCLogo,
  StyledKBCLogoWrapper
} from "./style"

const LayoutDefault: React.FC = ({ children }) => {
  const { t } = useTranslate()

  return (
    <>
      <StyledHeader>
        <StyledLogoWrapper>
          <StyledLogo />
        </StyledLogoWrapper>
      </StyledHeader>
      <StyledLayout>
        <StyledLayoutAside>
          <StyledLayoutAsideBody>
            <div>
              <Heading>{t("form.aside.heading")}</Heading>
              <p>
                {t("form.aside.description", {
                  br: <br />
                })}
              </p>
            </div>
            <StyledKBCLogoWrapper>
              <StyledKBCLogo />
            </StyledKBCLogoWrapper>
          </StyledLayoutAsideBody>
        </StyledLayoutAside>
        <StyledLayoutMain>
          <StyledLayoutSection>
            <StyledContainer>{children}</StyledContainer>
          </StyledLayoutSection>
        </StyledLayoutMain>
      </StyledLayout>
      <StyledFooter>
        <StyledDivider />
        <StyledFooterContent>
          <div>
            <Copyright>
              {t("system.legal.copyright", { year: new Date().getFullYear() })}
            </Copyright>
          </div>
        </StyledFooterContent>
      </StyledFooter>
    </>
  )
}

export default LayoutDefault
