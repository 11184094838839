import { StyledError } from "./style"

interface ErrorProps {
  className?: string
}

const Error: React.FC<ErrorProps> = ({ children, className }) => {
  return <StyledError className={className}>{children}</StyledError>
}

export default Error
