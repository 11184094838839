import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { Provider } from "react-redux"
import { persistor, store } from "state/store"
import "normalize.css"
import IntlProvider from "app/providers/IntlProvider"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import GlobalFonts from "./style/fonts"
import GlobalStyle from "./style/global"
import { theme } from "./style/theme"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider>
          <ThemeProvider theme={theme}>
            <GlobalFonts />
            <GlobalStyle />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
