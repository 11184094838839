import styled from "styled-components"
import { ReactComponent as Logo } from "assets/img/logo.svg"
import { ReactComponent as KBCLogo } from "assets/img/kbc-partnership.svg"
import { BaseLayoutCSS } from "../style.shared"

export const StyledLayout = styled.div`
  ${BaseLayoutCSS};
  grid-template-rows: auto 1fr;

  @media ${({ theme }) => theme.media.md_up} {
    grid-template-rows: 1fr auto;
    grid-template-columns: 40% 60%;
  }
`

export const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
`

export const StyledLayoutSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]};

  @media ${({ theme }) => theme.media.lg_up} {
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[4]};
  }
`

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`

export const StyledLayoutAside = styled.aside`
  position: relative;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[3]};

  @media ${({ theme }) => theme.media.md_up} {
    position: sticky;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    padding: ${({ theme }) => theme.space[3]};
  }

  @media ${({ theme }) => theme.media.lg_up} {
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[5]};
  }
`

export const StyledLayoutAsideBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.muted};
  height: 100%;
  padding: ${({ theme }) => theme.space[2]}};

  @media ${({ theme }) => theme.media.md_up} {
    padding: 5.1rem ${({ theme }) => theme.space[2]}};
  }
  @media ${({ theme }) => theme.media.lg_up} {
    padding: 5.6rem ${({ theme }) => theme.space[5]}};
  }
`

export const StyledLayoutImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.media.md_down} {
    height: 100%;
    object-position: left center;
  }
  @media ${({ theme }) => theme.media.md_up} {
    height: 100%;
    object-position: center center;
  }
`

export const StyledLogoWrapper = styled.div`
  position: relative;
  z-index: 1;
  max-width: 100%;
  aspect-ratio: 382 / 84;
  height: 25px;
`

export const StyledLogo = styled(Logo)`
  display: block;
  width: 100%;
  height: 100%;
`

export const StyledKBCLogoWrapper = styled.div`
  height: 40px;

  @media ${({ theme }) => theme.media.lg_up} {
    height: 50px;
  }
`

export const StyledKBCLogo = styled(KBCLogo)`
  display: inline-block;
  height: 100%;
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: end;
  padding: ${({ theme }) => theme.space[3]};
    
  @media ${({ theme }) => theme.media.lg_up} {
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]};
  }
`

export const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.colors.muted};
  padding: ${({ theme }) => theme.space[3]};
  
  @media ${({ theme }) => theme.media.lg_up} {
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[5]};
  }
`

export const StyledFooterContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`

export const StyledDivider = styled.div`
  background: ${({ theme }) => theme.colors.black};
  height: 1px;
  margin-bottom: ${({ theme }) => theme.space[3]};
`
