import { Locales, locales } from "i18n"

export const getDefaultLocale = () => {
  const userLocale = navigator.language as Locales

  if (locales.includes(userLocale)) {
    return userLocale
  } else {
    return Locales.NL
  }
}
