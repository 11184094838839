import { StyledDescription } from "./style"

type DescriptionProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  "ref"
>

const Description: React.FC<DescriptionProps> = ({ children, ...rest }) => {
  return <StyledDescription {...rest}>{children}</StyledDescription>
}

export default Description
