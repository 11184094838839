import { StyledSpace } from "./style"

export interface SpaceProps {
  top?: number
  bottom?: number
}
const Space: React.FC<SpaceProps> = ({ children, ...rest }) => {
  return <StyledSpace {...rest}>{children}</StyledSpace>
}

export default Space
