import { useTranslate } from "hooks/translate"
import { StyledContainer } from "../Default/style"
import { StyledBaseLayoutFooter } from "../style.shared"

const LayoutFooter: React.FC = props => {
  const { t } = useTranslate()
  const currentYear = new Date().getFullYear()

  return (
    <StyledBaseLayoutFooter {...props}>
      <StyledContainer>
        {t("footer.copyright", { year: currentYear })}
      </StyledContainer>
    </StyledBaseLayoutFooter>
  )
}

export default LayoutFooter
