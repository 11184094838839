import { ReactComponent as ArrowLeft } from "app/assets/svg/arrow-left.svg"
import { ReactComponent as ArrowRight } from "app/assets/svg/arrow-right.svg"
import { useTranslate } from "hooks/translate"
import { useFormContext } from "react-hook-form"
import { useGetFormStep } from "app/hooks/useGetFormStep"
import FormButton from "../Button"
import {
  StyledButtonLoadingIndicator,
  StyledButtonLoadingIndicatorWrapper,
  StyledFooter
} from "./style"

const Footer: React.FC<any> = ({ onPrev }) => {
  const { t } = useTranslate()
  const { step, isLastStep } = useGetFormStep()
  const {
    formState: { isSubmitting }
  } = useFormContext()

  return (
    <StyledFooter>
      {step > 1 && (
        <FormButton
          key={`button-prev-${step}`}
          type="button"
          onClick={onPrev}
          variant="text"
          icon={ArrowLeft}
          iconPlacement="prefix"
          action="prev"
        >
          {t("system.back")}
        </FormButton>
      )}
      <FormButton
        key={`button-next-${step}`}
        icon={ArrowRight}
        iconPlacement="postfix"
        action="next"
        disabled={isSubmitting}
      >
        {isSubmitting && (
          <StyledButtonLoadingIndicatorWrapper>
            <StyledButtonLoadingIndicator />
          </StyledButtonLoadingIndicatorWrapper>
        )}
        {t(isLastStep ? "system.complete" : "system.next")}
      </FormButton>
    </StyledFooter>
  )
}

export default Footer
