import React from "react"
import Field, { BaseFieldProps } from "app/components/Form/Field"
import Select, { SelectOptionProps } from "app/components/Select"
import { useTheme } from "styled-components"

export interface SelectFieldProps extends BaseFieldProps {
  options: SelectOptionProps[]
  placeholder?: string
}

const SelectField: React.FC<SelectFieldProps> = props => {
  const { name, placeholder, options, labelPlacement } = props
  const { space, colors } = useTheme()

  return (
    <Field
      {...props}
      floatingLabelEnabled={false}
      render={({ field: { onChange, value } }) => (
        <Select
          id={name}
          placeholder={placeholder}
          value={value as string | number}
          onChange={option => {
            onChange(option?.value)
          }}
          options={options}
          styles={{
            valueContainer: () => ({
              padding: `${space[1]} 0`
            }),
            menu: () => ({
              marginTop: 0
            }),
            container: (provided, { isFocused }) => ({
              ...provided,
              ...(labelPlacement === "postfix" ? { flex: "0 0 120px" } : {}),
              borderBottom: `1px solid ${
                isFocused ? colors.black : colors.gray_light
              }`
            })
          }}
        />
      )}
    />
  )
}

export default SelectField
