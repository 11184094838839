import { useDispatch, useSelector } from "react-redux"
import Body from "app/components/Form/Body"
import InputField from "app/components/Form/Field/Input"
import SelectField from "app/components/Form/Field/Select"
import Heading from "app/components/Heading"
import Description from "app/components/Description"
import { useTranslate } from "hooks/translate"
import { getAppLocale } from "state/app/selectors"
import investmentTooltipNL from "assets/img/tooltip-investment-nl.jpg"
import investmentTooltipNL2x from "assets/img/tooltip-investment-nl@2x.jpg"
import investmentTooltipFR from "assets/img/tooltip-investment-fr.jpg"
import investmentTooltipFR2x from "assets/img/tooltip-investment-fr@2x.jpg"
import { validateAmount, validateMax, validateMin } from "app/validation"
import { Navigate } from "react-router-dom"
import {
  getLeadResponse,
  getLeadResponseStatus,
  getVerifyLeadResponse
} from "state/form/selectors"
import { setData, verifyLeadThunk } from "state/form/slice"
import Button from "app/components/Button"
import { useFormContext } from "react-hook-form"
import { FormStateValues } from "state/form/types"
import { createVerificationRequest } from "app/api"
import { useEffect } from "react"
import { StyledButtonLoadingIndicator } from "app/components/Form/Footer/style"
import { CarModels } from "app/enums/carModels.enum"
import { RequestStatus } from "state/types"
import { StyledButton, StyledButtonWrapper } from "./style"

const carModels = [
  {
    key: "polestar_2",
    value: CarModels.POLESTAR_2
  }, {
    key: "polestar_3",
    value: CarModels.POLESTAR_3
  }, {
    key: "polestar_4",
    value: CarModels.POLESTAR_4
  }
]

const tooltipImg = {
  nl: {
    src: investmentTooltipNL,
    srcSet: `${investmentTooltipNL} 1x, ${investmentTooltipNL2x} 2x`
  },
  fr: {
    src: investmentTooltipFR,
    srcSet: `${investmentTooltipFR} 1x, ${investmentTooltipFR2x} 2x`
  },
  en: {
    src: investmentTooltipNL,
    srcSet: `${investmentTooltipNL} 1x, ${investmentTooltipNL2x} 2x`
  }
}

const MIN_INVESTMENT_AMOUNT = 30000
const MAX_INVESTMENT_AMOUNT = 150000

const Step3: React.FC = () => {
  const dispatch = useDispatch()
  const { data: verified, status: verifyStatus } = useSelector(
    getVerifyLeadResponse
  )
  const lead = useSelector(getLeadResponse)
  const { t } = useTranslate()
  const locale = useSelector(getAppLocale)
  const { isRejected } = useSelector(getLeadResponseStatus)
  const { getValues, clearErrors, setError } = useFormContext<FormStateValues>()

  if (isRejected) {
    return <Navigate to="/error" replace />
  }

  const verify = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const verificationCode = getValues().verificationCode
    if (verificationCode) {
      dispatch(verifyLeadThunk({ verificationCode }))
      dispatch(setData(getValues()))
    }
  }

  const resend = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const id = lead.id
    if (id) await createVerificationRequest(id)
  }

  const carModelOptions = carModels.map(
    model => ({
      label: t(`system.car_model.${model.key}`),
      value: model.value
    })
  )

  useEffect(() => {
    if (verified) clearErrors()
  }, [verified])

  useEffect(() => {
    if (verifyStatus === RequestStatus.REJECTED) {
      setError("verificationCode", {
        message: t("error.verification_code_valid")
      })
    }
  }, [verifyStatus])

  return (
    <>
      <Body>
        <Heading>{t("form.step_3.heading")}</Heading>
        <Description>{t("form.step_3.body")}</Description>
      </Body>
      <SelectField
        name="carModel"
        label="form.field.car_model.label"
        options={carModelOptions}
        rules={{ required: true }}
      />
      <InputField
        name="netInvestmentAmount"
        label="system.net_investment_amount"
        inputMode="numeric"
        rules={{
          required: true,
          validate: {
            amount: validateAmount,
            min: validateMin(MIN_INVESTMENT_AMOUNT),
            max: validateMax(MAX_INVESTMENT_AMOUNT)
          }
        }}
        tooltip={
          <img
            {...tooltipImg[locale]}
            width={260}
            height={290}
            style={{ display: "block" }}
          />
        }
      />
      <InputField
        name="supplierOrderNumber"
        label="system.order_number"
        tooltip={t("tooltip.order_number")}
      />
      <InputField
        name="verificationCode"
        label="system.verification_code"
        tooltip={t("tooltip.verification_code")}
        inputMode="numeric"
        disabled={verified}
        message={verified ? t("form.step_3.verification_code_verified") : undefined}
        rules={{
          required: true,
          validate: {
            verification_code_verified: () => verified
          }
        }}
      />
      {!verified && (
        <StyledButtonWrapper>
          <StyledButton className="random" disabled={verified} onClick={verify} variant={"contained"}>
            {t("system.verify")}
            {verifyStatus === RequestStatus.PENDING && (
              <StyledButtonLoadingIndicator />
            )}
          </StyledButton>
          <Button disabled={verified} onClick={resend} variant={"plain"}>
            {t("system.resend_verification")}
          </Button>
        </StyledButtonWrapper>
      )}
    </>
  )
}

export default Step3
