import React from "react"
import { Link } from "react-router-dom"
import Button from "app/components/Button"
import Heading from "app/components/Heading"
import Box from "app/components/Box"
import { useTranslate } from "hooks/translate"
import { parseTags } from "i18n/utils/parseTags"

const Error: React.FC = () => {
  const { t } = useTranslate()

  return (
    <Box>
      <Heading>{t("page.error.heading", parseTags)}</Heading>
      <Button component={<Link to="/" />}>
        {t("page.error.button.label")}
      </Button>
    </Box>
  )
}

export default Error
