import { RootState } from "state/store"
import { RequestStatus } from "state/types"
import { PreOwnedFormStateValues } from "./types"

export const getLeadResponse = (state: RootState) => {
  return state.preownedFormReducer.leadResponse.data
}

export const getFormData = (state: RootState) => {
  return state.preownedFormReducer.data
}

export const getFormFieldData =
  (field: keyof PreOwnedFormStateValues) => (state: RootState) => {
    return getFormData(state)[field]
  }

export const getLeadResponseStatus = (state: RootState) => {
  const { status } = state.preownedFormReducer.leadResponse

  return {
    isIdle: status === RequestStatus.IDLE,
    isPending: status === RequestStatus.PENDING,
    isFulfilled: status === RequestStatus.FULFILLED,
    isRejected: status === RequestStatus.REJECTED
  }
}
