import styled from "styled-components"

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space[4]};
`

export const StyledCurrentStep = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`
