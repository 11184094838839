import React from "react"
import { useIntl } from "react-intl"

export type TranslateValueFunction = (
  chunks: React.ReactNode[]
) => React.ReactElement
export interface TranslateValues {
  [key: string]: string | number | JSX.Element | TranslateValueFunction
}

export const useTranslate = () => {
  const { formatMessage } = useIntl()

  const translate = (id: string, values?: TranslateValues): string => {
    return formatMessage({ id }, values as {})
  }
  return { t: translate }
}
