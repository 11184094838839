import { useGetFieldError } from "app/hooks/useGetFieldError"
import { useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Controller, useFormContext } from "react-hook-form"
import { getEnvVariable } from "utils"
import { FieldProps } from "app/components/Form/Field"
import { Locales } from "i18n"
import FormError from "../../Error"

const CAPTCHA_SITE_KEY = getEnvVariable("REACT_APP_CAPTCHA_KEY")

type CaptchaFieldProps = Pick<FieldProps, "name" | "rules"> & {
  lang: Locales
}

const CaptchaField: React.FC<CaptchaFieldProps> = ({
  name,
  lang
}) => {
  const { control, setValue } = useFormContext()
  const error = useGetFieldError(name)

  useEffect(() => {
    setValue(name, "")
  }, [])

  return (
    <>
      <Controller
        name="captcha"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange } }) => (
          <ReCAPTCHA
            sitekey={CAPTCHA_SITE_KEY}
            hl={lang}
            onChange={token => {
              onChange(token)
            }}
          />
        )}
      />
      {error && <FormError>{error}</FormError>}
    </>
  )
}

export default CaptchaField
