import React from "react"
import {
  StyledOverView,
  StyledOverViewBody,
  StyledOverViewFooter,
  StyledOverViewHeader
} from "./style"

export interface OverviewProps {
  header?: React.ReactNode
  footer?: React.ReactNode
}

interface OverviewCompositionProps {
  Header: React.FC
  Body: React.FC
  Footer: React.FC
}

const Overview: React.FC<OverviewProps> & OverviewCompositionProps = ({
  children
}) => {
  return <StyledOverView>{children}</StyledOverView>
}

Overview.Header = ({ children }) => {
  return <StyledOverViewHeader>{children}</StyledOverViewHeader>
}

Overview.Body = ({ children }) => {
  return <StyledOverViewBody>{children}</StyledOverViewBody>
}

Overview.Footer = ({ children }) => {
  return <StyledOverViewFooter>{children}</StyledOverViewFooter>
}

export default Overview
