type ValidateMinMinMaxFunction = (
  threshold: number
) => (value: string) => boolean

export const validateAmount = (amount: string) => /^\d+$/.test(amount)

export const validateMax: ValidateMinMinMaxFunction =
  (threshold: number) => (value: string) => {
    return parseInt(value, 10) <= threshold
  }

export const validateMin = (threshold: number) => (value: string) => {
  return parseInt(value, 10) >= threshold
}
