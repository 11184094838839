type AnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

interface ParseLinksParams {
  [key: string]: AnchorProps
}

type ParseLinksFunction = (links: ParseLinksParams) => {
  [key: string]: () => JSX.Element
}

export const parseLinks: ParseLinksFunction = links => {
  return Object.entries(links).reduce((prev, [tag, attrs]) => {
    return {
      ...prev,
      [tag]: (chunks: React.ReactNode[]) => {
        const [chunk] = chunks as [string]
        return <a {...attrs}>{chunk}</a>
      }
    }
  }, {})
}
