import axios, { AxiosResponse } from "axios"
import {
  SimulateProposalsResponseData,
  FormStateData,
  LeadResponseData,
  TrackLeadRequestBody,
  AcceptProposalsBody,
  VerifyCaptchaRequestBody,
  VerifyLeadResponseData,
} from "state/form/types"
import { getEnvVariable } from "utils"

const API_URL = getEnvVariable("REACT_APP_API_URL")

interface CheckVATNumberReturn {
  valid: boolean
}

interface AxiosConfig {
  baseUrl: string
}

const instance = axios.create({
  baseURL: API_URL
})

export const verifyLeadRequest = async (id: number,  verificationCode: number): Promise<VerifyLeadResponseData> => {
  const { data } = await instance.patch(`/leads/${id}/verifications`, { verificationCode })
  return data
}

export const createVerificationRequest = async (id: number): Promise<void> => {
  await instance.post(`/leads/${id}/verifications`)
}

export const checkVATNumber = async (enterpriseNumber: string) => {
  const {
    data: { valid }
  } = await instance.post<CheckVATNumberReturn>("/vies", {
    enterpriseNumber
  })

  return valid
}

export const simulateProposals = async (
  data: FormStateData
): Promise<AxiosResponse<SimulateProposalsResponseData, AxiosConfig>> =>
  await instance.post("/proposals/simulate", data)

export const acceptProposals = async (
  data: AcceptProposalsBody
): Promise<AxiosResponse<void, AxiosConfig>> =>
  await instance.post("/proposals/accept", data)

export const createLeadRequest = async (
  data: TrackLeadRequestBody
): Promise<AxiosResponse<LeadResponseData, AxiosConfig>> =>
  await instance.post("/leads", data)

export const updateLeadRequest = async (
  leadId: number,
  data: TrackLeadRequestBody
): Promise<AxiosResponse<void, AxiosConfig>> =>
  await instance.patch(`/leads/${leadId}`, data)

export const verifyCaptchaRequest = async (
  data: VerifyCaptchaRequestBody
): Promise<AxiosResponse<void, AxiosConfig>> =>
  await instance.post("/captcha/verify", data)
