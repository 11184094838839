import styled from "styled-components"

export const StyledForm = styled.form`
  position: relative;
`

export const StyledFormLoading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`
