import Button from "app/components/Button"
import styled from "styled-components"
import { FormButtonProps } from "app/components/Form/Button"

type StyledFormButtonProps = FormButtonProps

export const StyledFormButton = styled(Button)<StyledFormButtonProps>`
  position: relative;
  ${({ action }) => {
    switch (action) {
      case "prev":
        return `margin-right: auto;`
      case "next":
        return `margin-left: auto;`
    }
  }};
`
