import React from "react"
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  StylesConfig
} from "react-select"
import ReactSelect from "react-select"
import { useTheme } from "styled-components"
import { useTranslate } from "hooks/translate"
import Option from "./Option"
import DropdownIndicator from "./DropdownIndicator"

export type SelectOptionProps = {
  label: string
  value: string | number
}

export type SelectProps = {
  id: string
  value: SelectOptionProps["value"] | undefined
  options: SelectOptionProps[]
  onChange?: (
    value: OnChangeValue<SelectOptionProps, false>,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => void
  placeholder?: string
  align?: "left" | "right"
  styles?: StylesConfig<SelectOptionProps, false, GroupBase<SelectOptionProps>>
  className?: string
}

const Select: React.FC<SelectProps> = ({
  id,
  value,
  options,
  onChange,
  placeholder: customPlaceholder,
  align,
  styles = {},
  className
}) => {
  const { space, colors } = useTheme()
  const defaultValue = options.find(opt => opt.value === value)
  const { t } = useTranslate()

  const defaultPlaceholder = t("form.select.placeholder", {
    article: 2,
    option: t("system.option").toLowerCase()
  })
  const placeholderText = customPlaceholder || defaultPlaceholder
  const {
    control,
    valueContainer,
    singleValue,
    menu,
    menuList,
    option,
    placeholder,
    ...restStyles
  } = styles

  return (
    <ReactSelect
      name={id}
      inputId={id}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      placeholder={placeholderText}
      openMenuOnFocus={true}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
        Option
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0
      })}
      styles={{
        control: (provided, state) => ({
          ...provided,
          cursor: "pointer",
          minHeight: 0,
          borderWidth: 0,
          boxShadow: "none",
          ...control?.(provided, state)
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          padding: 0,
          ...valueContainer?.(provided, state)
        }),
        singleValue: (provided, state) => ({
          ...provided,
          marginLeft: 0,
          marginRight: space[0],
          color: "inherit",
          ...singleValue?.(provided, state)
        }),
        menu: (provided, state) => ({
          ...provided,
          marginBottom: 0,
          marginTop: space[1],
          border: `1px solid ${colors.gray}`,
          boxShadow: "none",
          ...(align === "right"
            ? {
                width: "auto",
                right: 0
              }
            : {}),
          ...menu?.(provided, state)
        }),
        menuList: (provided, state) => ({
          ...provided,
          paddingBottom: space[0],
          paddingTop: space[0],
          ...menuList?.(provided, state)
        }),
        option: (provided, state) => ({
          ...provided,
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          padding: `${space[0]} ${space[1]}`,
          background: state.isFocused ? colors.gray_light : "transparent",
          color:
            state.isSelected || state.isFocused ? colors.black : colors.gray,
          "&:active": {
            background: "none"
          },
          ...option?.(provided, state)
        }),
        placeholder: (provided, state) => ({
          ...provided,
          margin: 0,
          color: colors.black,
          ...placeholder?.(provided, state)
        }),
        ...restStyles
      }}
      isSearchable={false}
      className={className}
    />
  )
}

export default Select
