import { useParams } from "react-router-dom"
import { STEPS } from "router"

export const useGetFormStep = () => {
  const { step: stepString } = useParams()
  const step = Number(stepString)
  const numberOfSteps = Object.keys(STEPS).length

  return {
    step,
    numberOfSteps,
    isLastStep: step === numberOfSteps
  }
}
