import InputField from "app/components/Form/Field/Input"
import SelectField from "app/components/Form/Field/Select"
import Heading from "app/components/Heading"
import Description from "app/components/Description"
import Body from "app/components/Form/Body"
import { useTranslate } from "hooks/translate"
import { ActivityCategoryCodes } from "state/form/types"
import { checkVATNumber } from "app/api"
import { getCaptchaResponseStatus, getLeadResponseStatus } from "state/form/selectors"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

const Step2: React.FC = () => {
  const { t } = useTranslate()
  const { isRejected: isCaptchaRejected } = useSelector(getCaptchaResponseStatus)
  const { isRejected: isLeadRejected } = useSelector(getLeadResponseStatus)

  if (isCaptchaRejected || isLeadRejected) {
    return <Navigate to="/error" replace />
  }

  const activityCategoryCodes = Object.entries(ActivityCategoryCodes).map(
    ([key, value]) => ({
      label: t(`system.industry.${key.toLowerCase()}`),
      value
    })
  )
  const activityCategoryCodePlaceholder = t("form.select.placeholder", {
    article: 1,
    option: t("system.sector").toLowerCase()
  })

  return (
    <>
      <Body>
        <Heading>{t("form.step_2.heading")}</Heading>
        <Description>{t("form.step_2.body")}</Description>
      </Body>
      <InputField
        name="enterpriseNumber"
        label="system.enterprise_number"
        rules={{
          required: true,
          validate: {
            enterprise_number: async enterpriseNumber =>
              await checkVATNumber(enterpriseNumber)
          }
        }}
        tooltip={t("tooltip.enterprise_number")}
        description={t("form.field.enterpriseNumber.description")}
      />
      <InputField
        name="enterpriseName"
        label="system.enterprise_name"
        rules={{ required: true, maxLength: 35 }}
      />
      <SelectField
        name="activityCategoryCode"
        label="system.industry"
        options={activityCategoryCodes}
        placeholder={activityCategoryCodePlaceholder}
        rules={{ required: true }}
      />
    </>
  )
}

export default Step2
