import { createGlobalStyle } from "styled-components"
import { DefaultTheme } from "styled-components"

export default createGlobalStyle<{ theme: DefaultTheme }>`
  html {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.body}, 'Arial', sans-serif;
    font-size: 62.5%;
  }
  body {
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #root {
    display: grid;
  }
  p {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
  a {
    color: ${({ theme }) => theme.colors.gray_dark};
    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  svg {
    fill: currentColor;
  }
  ul {
    padding-inline-start: 1.4rem;
    margin-top: 0;
  }
  li::marker {
    color: ${({ theme }) => theme.colors.primary};
  }
  dl {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 0;
  }
  dt {
    margin-bottom: ${({ theme }) => theme.space[1]};
  }
  dd {
    margin-left: ${({ theme }) => theme.space[3]};
    font-weight: 700;
  }

  @media ${({ theme }) => theme.media.lg_down} {
    dl {
      grid-template-columns: 1fr auto;
    }
    dd {
      text-align: right;
    }
  }
`
