import LanguageSwitch from "app/components/LanguageSwitch"
import { useGetFormStep } from "app/hooks/useGetFormStep"
import { useTranslate } from "hooks/translate"
import { forwardRef } from "react"
import { StyledCurrentStep, StyledHeader } from "./style"

const Header = forwardRef<HTMLDivElement>((_, ref) => {
  const { step, numberOfSteps } = useGetFormStep()
  const { t } = useTranslate()

  return (
    <StyledHeader ref={ref}>
      <span>
        {t("form.step_indicator", {
          step: <StyledCurrentStep>{step}</StyledCurrentStep>,
          maxStep: numberOfSteps
        })}
      </span>
      <LanguageSwitch />
    </StyledHeader>
  )
})

export default Header
