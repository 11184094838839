import { RootState } from "state/store"
import { RequestStatus } from "state/types"
import { FormStateValues } from "./types"

export const getLeadResponse = (state: RootState) => {
  return state.formReducer.leadResponse.data
}

export const getValidatedStep = (state: RootState) => {
  return state.formReducer.validatedStep
}

export const getFormData = (state: RootState) => {
  return state.formReducer.data
}

export const getFormFieldData =
  (field: keyof FormStateValues) => (state: RootState) => {
    return getFormData(state)[field]
  }

export const getCarModel = (state: RootState) => {
  return state.formReducer.data.carModel
}

export const getSimulateProposalsResponse = (state: RootState) => {
  return state.formReducer.simulateProposalsResponse.data
}

export const getSimulateProposalsResponseStatus = (state: RootState) => {
  const { status } = state.formReducer.simulateProposalsResponse

  return {
    isIdle: status === RequestStatus.IDLE,
    isPending: status === RequestStatus.PENDING,
    isFulfilled: status === RequestStatus.FULFILLED,
    isRejected: status === RequestStatus.REJECTED
  }
}

export const getVerifyLeadResponse = (state: RootState) => {
  return state.formReducer.verifyLeadResponse
}

export const getAcceptProposalsResponseStatus = (state: RootState) => {
  const { status } = state.formReducer.acceptProposalsResponse

  return {
    isIdle: status === RequestStatus.IDLE,
    isPending: status === RequestStatus.PENDING,
    isFulfilled: status === RequestStatus.FULFILLED,
    isRejected: status === RequestStatus.REJECTED
  }
}

export const getLeadResponseStatus = (state: RootState) => {
  const { status } = state.formReducer.leadResponse

  return {
    isIdle: status === RequestStatus.IDLE,
    isPending: status === RequestStatus.PENDING,
    isFulfilled: status === RequestStatus.FULFILLED,
    isRejected: status === RequestStatus.REJECTED
  }
}

export const getCaptchaResponseStatus = (state: RootState) => {
  const { status } = state.formReducer.captchaResponse

  return {
    isIdle: status === RequestStatus.IDLE,
    isPending: status === RequestStatus.PENDING,
    isFulfilled: status === RequestStatus.FULFILLED,
    isRejected: status === RequestStatus.REJECTED
  }
}
