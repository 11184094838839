import styled, { css } from "styled-components"

export const BaseLayoutCSS = css`
  display: grid;
  min-height: 100vh;
`

export const StyledBaseLayoutFooter = styled.footer`
  margin-top: auto;
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
  background: ${({ theme }) => theme.colors.muted};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1.2rem;

  @media ${({ theme }) => theme.media.lg_up} {
    padding: ${({ theme }) => `${theme.space[3]} ${theme.space[5]}`};
  }
`
