import styled, { css } from "styled-components"
import { InputFieldProps } from "app/components/Form/Field/Input"

export type StyledInputProps = Omit<InputFieldProps, "label">

const FloatingStyledInputCSS = css`
  flex: 0 0 120px;
  min-width: 0;
`

export const StyledInput = styled.input<StyledInputProps>`
  ${({ labelPlacement }) =>
    labelPlacement === "postfix" && FloatingStyledInputCSS};
  padding: ${({ theme }) => theme.space[1]} 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_light};
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.black};
  }
`
