import { StyledHeading } from "./style"

export type HeadingTypes = "h1" | "h2"

export interface HeadingProps {
  variant?: "normal" | "strong" | "italic"
  as?: HeadingTypes
}

const Heading: React.FC<HeadingProps> = ({ as = "h1", children, ...rest }) => {
  return (
    <StyledHeading as={as} {...rest}>
      {children}
    </StyledHeading>
  )
}

export default Heading
