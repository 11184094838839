import styled, { css } from "styled-components"
import { FieldProps } from "app/components/Form/Field/"
import Tooltip from "app/components/Tooltip"
import Description from "app/components/Description"

type FloatingElementProps = {
  floatingLabel: boolean
}
type StyledFieldProps = Pick<FieldProps, "labelPlacement">
type StyledLabelProps = Pick<FieldProps, "labelPlacement"> &
  FloatingElementProps
type StyledFieldTooltipProps = FloatingElementProps

export const StyledFieldContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space[5]};
`

const FieldPostfixCSS = css`
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
`

const FieldDefaultCSS = css`
  flex-direction: column;
`

export const StyledField = styled.div<StyledFieldProps>`
  display: flex;
  ${({ labelPlacement }) => {
    switch (labelPlacement) {
      case "postfix":
        return FieldPostfixCSS
      default:
        return FieldDefaultCSS
    }
  }};
`

export const StyledFieldHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[0]};
`

const FloatingElement = css<FloatingElementProps>`
  transition: transform 200ms ease;
  ${({ floatingLabel }) =>
    floatingLabel &&
    `
    transform: translateY(100%);
  `};
`

export const StyledFieldTooltip = styled(Tooltip)<StyledFieldTooltipProps>`
  ${FloatingElement};
`

const LabelPostfixCSS = css`
  align-self: center;
`

const LabelDefaultCSS = css`
  align-self: flex-start;
`

export const StyledLabel = styled.label<StyledLabelProps>`
  ${FloatingElement};
  color: ${({ theme }) => theme.colors.gray};

  ${({ labelPlacement }) => {
    switch (labelPlacement) {
      case "postfix":
        return LabelPostfixCSS
      default:
        return LabelDefaultCSS
    }
  }};

  &[for] {
    cursor: pointer;
  }
`

export const StyledFormDescription = styled(Description)`
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.space[0]};
  font-size: ${({ theme }) => theme.fontSizes.small};
`
