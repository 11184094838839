import LoadingIndicator from "app/components/LoadingIndicator"
import styled from "styled-components"

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.space[5]};
`

export const StyledButtonLoadingIndicatorWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledButtonLoadingIndicator = styled(LoadingIndicator)`
  circle {
    stroke: ${({ theme }) => theme.colors.white};
  }
`
