import React from "react"
import { BaseFieldProps } from "app/components/Form/Field"
import { Controller, useFormContext } from "react-hook-form"
import { useGetFieldError } from "app/hooks/useGetFieldError"
import FormError from "../../Error"
import { DefaultInputProps } from "../types"
import { StyledFieldContainer, StyledInput, StyledLabel } from "./style"

export interface RadioFieldProps extends BaseFieldProps, DefaultInputProps {}

const RadioField: React.FC<RadioFieldProps> = props => {
  const { label, rules, name, ...rest } = props
  const { control } = useFormContext()
  const error = useGetFieldError(name)

  return (
    <StyledFieldContainer>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <StyledLabel>
              <StyledInput
                {...rest}
                id={name}
                name={name}
                type="checkbox"
                value={value}
                defaultChecked={!!value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {label}
            </StyledLabel>
          )
        }}
      />
      {error && <FormError>{error}</FormError>}
    </StyledFieldContainer>
  )
}

export default RadioField
