import { matchPath, useLocation, useRoutes } from "react-router-dom"
import LayoutDefault from "app/components/Layout/Default"
import routes from "./router"

function App() {
  const { pathname } = useLocation()
  const matchedPaths = routes.find(({ path }) =>
    path ? matchPath(path, pathname) : false
  )
  const Layout = matchedPaths?.layout || LayoutDefault

  return <Layout>{useRoutes(routes)}</Layout>
}

export default App
