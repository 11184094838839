import {
  StyledContainer,
  StyledLayoutMain,
  StyledLayoutSection
} from "../Default/style"
import LayoutFooter from "../Footer"
import { StyledLayoutPlain } from "./style"

const LayoutPlain: React.FC = ({ children }) => {
  return (
    <StyledLayoutPlain>
      <StyledLayoutMain>
        <StyledLayoutSection>
          <StyledContainer>{children}</StyledContainer>
        </StyledLayoutSection>
      </StyledLayoutMain>
      <LayoutFooter />
    </StyledLayoutPlain>
  )
}

export default LayoutPlain
