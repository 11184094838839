import { useEffect, useRef } from "react"
import { SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useGetFormStep } from "app/hooks/useGetFormStep"
import { STEPS } from "router"
import {
  getFormData,
  getSimulateProposalsResponseStatus,
  getValidatedStep
} from "state/form/selectors"
import {
  acceptProposalsThunk,
  fetchProposalsThunk,
  setData,
  setValidatedStep,
  trackLeadThunk,
  verifyCaptchaThunk,
} from "state/form/slice"
import { FormStateData } from "state/form/types"
import Header from "app/components/Form/Header"
import Form from "app/components/Form"
import Footer from "app/components/Form/Footer"
import { FormSteps } from "app/enums/formSteps.enum"
import { getTrackLeadPayload } from "utils/form"

const NewCarFormPage: React.FC = () => {
  const formData = useSelector(getFormData)
  const validatedStep = useSelector(getValidatedStep)
  const { isPending } = useSelector(getSimulateProposalsResponseStatus)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const prevStepRef = useRef<number | undefined>()
  const { current: prevStep } = prevStepRef
  const { step, numberOfSteps } = useGetFormStep()
  const Step = STEPS[step]

  useEffect(() => {
    const activeStep = Math.min(validatedStep + 1, numberOfSteps)

    if (step > validatedStep + 1 || step > numberOfSteps) {
      navigate(`/${activeStep}`, { replace: true })
    } else if (step === 0 || isNaN(step)) {
      navigate(`/1`, { replace: true })
    }
  }, [step, validatedStep])

  useEffect(() => {
    prevStepRef.current = step
    if (!prevStep) return

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [step])

  const handleFormSubmit: SubmitHandler<FormStateData> = async payload => {
    dispatch(setData(payload))
    dispatch(setValidatedStep(step))

    switch (step) {
      case FormSteps.USER_INFO_STEP:
        dispatch(verifyCaptchaThunk())
        dispatch(trackLeadThunk(getTrackLeadPayload(
          step,
          payload
        )))
        break;
      case FormSteps.COMPANY_INFO_STEP:
        dispatch(trackLeadThunk(getTrackLeadPayload(
          step,
          payload
        )))
        break;
      case FormSteps.INVESTMENT_STEP:
        dispatch(trackLeadThunk(getTrackLeadPayload(
          step,
          payload
        )))
        break;
      case FormSteps.DESIRED_FINANCING_STEP:
        dispatch(trackLeadThunk(getTrackLeadPayload(
          step,
          payload
        )))
        dispatch(fetchProposalsThunk(payload))
        break;
      case FormSteps.VIEW_PROPOSALS_STEP:
        dispatch(acceptProposalsThunk())
        dispatch(trackLeadThunk(getTrackLeadPayload(
          step,
          payload
        )))
        break;
      default:
        break;
    }

    switch (step) {
      case numberOfSteps:
        navigate(`/success`)
        break
      default:
        navigate(`/${step + 1}`)
    }
  }

  const handlePrevStep = () => {
    navigate(`/${step - 1}`)
  }

  return (
    <>
      <Header ref={scrollRef} />
      <Form
        defaultValues={formData}
        onSubmit={handleFormSubmit}
        isPending={isPending}
      >
        {Step && <Step />}
        <Footer onPrev={handlePrevStep} />
      </Form>
    </>
  )
}

export default NewCarFormPage
