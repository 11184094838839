import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  simulateProposals,
  acceptProposals,
  createLeadRequest,
  updateLeadRequest,
  verifyCaptchaRequest,
  verifyLeadRequest
} from "app/api"
import { AxiosError } from "axios"
import { RootState } from "state/store"
import { RequestStatus } from "state/types"
import {
  SimulateProposalsResponse,
  SimulateProposalsResponseData,
  FormState,
  FormStateData,
  LeadResponse,
  LeadResponseData,
  TrackLeadRequestBody,
  VerifyLeadResponseData,
  VerifyLeadRequestBody
} from "./types"

const initialState: FormState = {
  validatedStep: 0,
  data: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    validQuotationDisclaimer: false,
    informationNotice: false,
    privacyPolicy: false,
    captcha: "",
    enterpriseNumber: "",
    enterpriseName: "",
    carModel: "",
    netInvestmentAmount: "",
    supplierOrderNumber: undefined,
    desiredFinancingAmount: "",
    duration: 60,
    verificationCode: undefined
  },
  simulateProposalsResponse: {
    data: {},
    status: RequestStatus.IDLE,
    errors: []
  },
  verifyLeadResponse: {
    data: false,
    status: RequestStatus.IDLE,
    errors: []
  },
  acceptProposalsResponse: {
    status: RequestStatus.IDLE,
    errors: []
  },
  leadResponse: {
    data: {},
    status: RequestStatus.IDLE,
    errors: []
  },
  captchaResponse: {
    status: RequestStatus.IDLE,
    errors: []
  }
}

interface FormStateError {
  state: RootState
  rejectValue: {
    errors: string[]
  }
}

export const fetchProposalsThunk = createAsyncThunk<
  SimulateProposalsResponseData,
  FormStateData,
  FormStateError
>("form/fetchProposals", async (payload, thunkAPI) => {
  try {
    const lead = thunkAPI.getState().formReducer.leadResponse.data as LeadResponseData
    const { data } = await simulateProposals({ ...payload, leadId: lead.id })
    return data
  } catch (err) {
    const error = err as AxiosError<{ message: string[] }>
    const errors = error.response?.data.message || []

    return thunkAPI.rejectWithValue({
      errors
    })
  }
})

export const acceptProposalsThunk = createAsyncThunk<
  void,
  void,
  FormStateError
>("form/acceptProposals", async (_payload, thunkAPI) => {
  try {
    const proposalsData = thunkAPI.getState().formReducer.simulateProposalsResponse.data as SimulateProposalsResponseData
    const formData = thunkAPI.getState().formReducer.data

    await acceptProposals({
      referenceIdentification: proposalsData.referenceIdentification,
      firstName: formData.firstName,
      lastName: formData.lastName,
      language: formData.language!,
      phone: formData.phone,
      email: formData.email,
      supplierOrderNumber: formData.supplierOrderNumber,
    })
  } catch (err) {
    const error = err as AxiosError<{ message: string[] }>
    const errors = error.response?.data.message || []

    return thunkAPI.rejectWithValue({
      errors
    })
  }
})

export const trackLeadThunk = createAsyncThunk<
  LeadResponseData,
  TrackLeadRequestBody,
  FormStateError
>("form/trackLead", async (payload, thunkAPI) => {
  try {
    const leadData = thunkAPI.getState().formReducer.leadResponse.data as LeadResponseData
    const { data: responseData } = leadData.id
      ? await updateLeadRequest(leadData.id, payload)
      : await createLeadRequest(payload)

    return responseData as LeadResponseData
  } catch (err) {
    const error = err as AxiosError<{ message: string[] }>
    const errors = error.response?.data.message || []

    return thunkAPI.rejectWithValue({
      errors
    })
  }
})

export const verifyLeadThunk = createAsyncThunk<
  VerifyLeadResponseData,
  VerifyLeadRequestBody,
  FormStateError
>("form/verifyLead", async (payload, thunkAPI) => {
  try {
    const lead = thunkAPI.getState().formReducer.leadResponse.data as LeadResponseData
    const result = await verifyLeadRequest(lead.id, payload.verificationCode)
    return result
  } catch (err) {
    const error = err as AxiosError<{ message: string[] }>
    const errors = error.response?.data.message || []

    return thunkAPI.rejectWithValue({
      errors
    })
  }
})

export const verifyCaptchaThunk = createAsyncThunk<
  void,
  void,
  FormStateError
>("form/verifyCaptcha", async (_payload, thunkAPI) => {
  try {
    const captchaToken = thunkAPI.getState().formReducer.data.captcha
    await verifyCaptchaRequest({ token: captchaToken })
  } catch (err) {
    const error = err as AxiosError<{ message: string[] }>
    const errors = error.response?.data.message || []

    return thunkAPI.rejectWithValue({
      errors
    })
  }
})

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<FormStateData>) => {
      const { payload } = action

      for (const property in payload) {
        const value = payload[property]
        state.data[property] = value
      }
    },
    setSimulateProposalsResponse: (state, action: PayloadAction<SimulateProposalsResponse>) => {
      state.simulateProposalsResponse = action.payload
    },
    setLeadResponse: (state, action: PayloadAction<LeadResponse>) => {
      state.leadResponse = action.payload
    },
    setValidatedStep: (state, action: PayloadAction<number>) => {
      state.validatedStep = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProposalsThunk.pending, state => {
      state.simulateProposalsResponse.status = RequestStatus.PENDING
    })
    builder.addCase(fetchProposalsThunk.fulfilled, (state, action) => {
      state.simulateProposalsResponse.status = RequestStatus.FULFILLED
      state.simulateProposalsResponse.data = action.payload
      state.simulateProposalsResponse.errors = []
    })
    builder.addCase(fetchProposalsThunk.rejected, (state, action) => {
      state.simulateProposalsResponse.status = RequestStatus.REJECTED
      state.simulateProposalsResponse.data = initialState.simulateProposalsResponse.data

      if (action.payload) {
        state.simulateProposalsResponse.errors = action.payload.errors
      } else {
        state.simulateProposalsResponse.errors = action.error.message
          ? [action.error.message]
          : []
      }
    })
    builder.addCase(acceptProposalsThunk.pending, state => {
      state.acceptProposalsResponse.status = RequestStatus.PENDING
    })
    builder.addCase(acceptProposalsThunk.fulfilled, state => {
      state.acceptProposalsResponse.status = RequestStatus.FULFILLED
      state.acceptProposalsResponse.errors = []
    })
    builder.addCase(acceptProposalsThunk.rejected, (state, action) => {
      state.acceptProposalsResponse.status = RequestStatus.REJECTED

      if (action.payload) {
        state.acceptProposalsResponse.errors = action.payload.errors
      } else {
        state.acceptProposalsResponse.errors = action.error.message
          ? [action.error.message]
          : []
      }
    })
    builder.addCase(trackLeadThunk.pending, state => {
      state.leadResponse.status = RequestStatus.PENDING
    })
    builder.addCase(trackLeadThunk.fulfilled, (state, action) => {
      state.leadResponse.status = RequestStatus.FULFILLED
      state.leadResponse.data = action.payload
      state.leadResponse.errors = []
      if (!action.payload.verifiedAt) {
        state.data.verificationCode = undefined
        state.verifyLeadResponse.data = false
      }
    })
    builder.addCase(trackLeadThunk.rejected, (state, action) => {
      state.leadResponse.status = RequestStatus.REJECTED
      state.leadResponse.data = initialState.leadResponse.data

      if (action.payload) {
        state.leadResponse.errors = action.payload.errors
      } else {
        state.leadResponse.errors = action.error.message
          ? [action.error.message]
          : []
      }
    })
    builder.addCase(verifyLeadThunk.pending, state => {
      state.verifyLeadResponse.status = RequestStatus.PENDING
    })
    builder.addCase(verifyLeadThunk.fulfilled, (state, action) => {
      state.verifyLeadResponse.status = RequestStatus.FULFILLED
      state.verifyLeadResponse.data = action.payload.isVerified
      state.verifyLeadResponse.errors = []
    })
    builder.addCase(verifyLeadThunk.rejected, (state, action) => {
      state.verifyLeadResponse.status = RequestStatus.REJECTED
      state.verifyLeadResponse.data = initialState.verifyLeadResponse.data

      if (action.payload) {
        state.verifyLeadResponse.errors = action.payload.errors
      } else {
        state.verifyLeadResponse.errors = action.error.message
          ? [action.error.message]
          : []
      }
    })
    builder.addCase(verifyCaptchaThunk.pending, state => {
      state.captchaResponse.status = RequestStatus.PENDING
    })
    builder.addCase(verifyCaptchaThunk.fulfilled, (state, _action) => {
      state.captchaResponse.status = RequestStatus.FULFILLED
      state.captchaResponse.errors = []
    })
    builder.addCase(verifyCaptchaThunk.rejected, (state, action) => {
      state.captchaResponse.status = RequestStatus.REJECTED

      if (action.payload) {
        state.captchaResponse.errors = action.payload.errors
      } else {
        state.captchaResponse.errors = action.error.message
          ? [action.error.message]
          : []
      }
    })
  }
})

export const { setData, setValidatedStep, setSimulateProposalsResponse, setLeadResponse } = formSlice.actions

export default formSlice.reducer
