import React from "react"
import { Navigate } from "react-router-dom"
import Button from "app/components/Button"
import Heading from "app/components/Heading"
import { useTranslate } from "hooks/translate"
import { Locales } from "i18n"
import { useSelector } from "react-redux"
import { getAppLocale } from "state/app/selectors"
import Space from "app/components/Space"
import { StyledBottomBox } from "app/components/Box/style"
import {
  getAcceptProposalsResponseStatus,
  getLeadResponseStatus
} from "state/form/selectors"
import { getEnvVariable } from "utils"

const href = {
  [Locales.NL]:
    "https://www.polestar.com/nl-be/test-drive/booking/ps2/at-polestar/",
  [Locales.FR]: "https://www.polestar.com/fr-be/test-drive/"
}

const SPECIAL_OFFER_POLESTAR_2 = getEnvVariable(
  "REACT_APP_SPECIAL_OFFER_POLESTAR_2"
)
const SPECIAL_OFFER_POLESTAR_3 = getEnvVariable(
  "REACT_APP_SPECIAL_OFFER_POLESTAR_3"
)

const NewCarSuccess: React.FC = () => {
  const { t } = useTranslate()
  const locale = useSelector(getAppLocale)
  const { isRejected: isAcceptProposalsRejected } = useSelector(
    getAcceptProposalsResponseStatus
  )
  const { isRejected: isLeadRejected } = useSelector(getLeadResponseStatus)

  if (isAcceptProposalsRejected || isLeadRejected) {
    return <Navigate to="/error" replace />
  }

  return (
    <>
      <Space bottom={5}>
        <Heading>{t("page.completed.heading")}</Heading>
        <p>
          {t("page.completed.body", {
            special_offer_p2: SPECIAL_OFFER_POLESTAR_2,
            special_offer_p3: SPECIAL_OFFER_POLESTAR_3,
            br: <br />
          })}
        </p>
      </Space>

      <StyledBottomBox>
        <Heading as="h2">{t("page.completed.cta.heading")}</Heading>
        <p>{t("page.completed.cta.body")}</p>
        <Button component={<a href={href[locale]} target="_blank" />}>
          {t("page.completed.cta.button.label")}
        </Button>
      </StyledBottomBox>
    </>
  )
}

export default NewCarSuccess
