export interface IconProps {
  component: React.ElementType
  className?: string
}

export const Icon: React.FC<IconProps> = ({
  component: Component,
  className
}) => {
  return <Component className={className} />
}
