import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux"
import { getAppLocale } from "state/app/selectors"
import messages from "../../i18n"

const AppIntlProvider: React.FC = ({ children }) => {
  const locale = useSelector(getAppLocale)

  return (
    <IntlProvider messages={messages[locale]} locale={locale}>
      {children}
    </IntlProvider>
  )
}

export default AppIntlProvider
