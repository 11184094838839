export const spaceValues = [4, 8, 16, 24, 32, 64]
export const space = spaceValues.map(s => `${s / 10}rem`)

export const theme = {
  fonts: {
    body: "Polestar Unica"
  },
  fontSizes: {
    body: "1.6rem",
    h1: "3rem",
    h2: "2.4rem",
    small: "1.4rem",
    extraSmall: "1.2rem"
  },
  lineHeights: {
    body: 1.25,
    heading: 1.2,
    large: 1.5
  },
  colors: {
    white: "#FFFFFF",
    black: "#111820",
    gray_light: "#B6B9BB",
    gray: "#707479",
    gray_dark: "#53565A",
    primary: "#FF7500",
    muted: "#ECECE6",
    error: "#9D1E07"
  },
  space,
  media: {
    md_down: `(max-width: 619px)`,
    md_up: `(min-width: 620px)`,
    lg_down: `(max-width: 869px)`,
    lg_up: `(min-width: 870px)`
  }
}

export type Theme = typeof theme
