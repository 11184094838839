import { Locales } from "i18n"

export const getEnvVariable = (name: string) => {
  return process.env[name] || ""
}

export const parseNumberStringToLocale = (
  numberString: string,
  minimumFractionDigits?: number
): string => {
  return parseFloat(numberString).toLocaleString("nl-BE", {
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: 2,
  })
}

export const parseEuroSignPlacement = (
  locale: Locales,
  numberString: string
): string => {
  return locale === Locales.NL
    ? `€ ${numberString}`
    : `${numberString} €`
}