import { configureStore, Reducer } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import localStorage from "redux-persist/es/storage"
import sessionStorage from "redux-persist/es/storage/session"
import formReducer from "./form/slice"
import { FormState } from "./form/types"
import appReducer from "./app/slice"
import { AppState } from "./app/types"
import preownedFormReducer from "./preownedForm/slice"

const persistLocalStorage = <T>(
  key: string,
  reducer: Reducer<T>,
  blacklist: string[] = []
) => {
  return persistReducer({ key, storage: localStorage, blacklist }, reducer)
}

const persistSessionStorage = <T>(
  key: string,
  reducer: Reducer<T>,
  blacklist: string[] = []
) => {
  return persistReducer({ key, storage: sessionStorage, blacklist }, reducer)
}

export const store = configureStore({
  reducer: {
    appReducer: persistLocalStorage<AppState>("appReducer", appReducer),
    formReducer: persistSessionStorage<FormState>("formReducer", formReducer),
    preownedFormReducer: preownedFormReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"]
      }
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
