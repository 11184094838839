import { useEffect, useState } from "react"
import { usePopper } from "react-popper"
import { ReactComponent as Info } from "app/assets/svg/info.svg"
import {
  StyledTooltip,
  StyledTooltipButton,
  StyledTooltipIcon,
  StyledTooltipTrigger
} from "./style"

export interface TooltipProp {
  className?: string
}

const Tooltip: React.FC<TooltipProp> = ({ children, className }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start"
  })
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleClickOutside: EventListener = event => {
      if (!popperElement || !referenceElement) return
      const target = event.composedPath?.()?.[0] || event.target

      if (target instanceof Node) {
        if (isVisible && referenceElement.contains(target)) return
        if (popperElement.contains(target)) return
        setIsVisible(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [isVisible, popperElement, referenceElement])

  const handleTriggerClick = () => {
    setIsVisible(true)
  }

  return (
    <>
      <StyledTooltipButton variant="plain" type="button" className={className}>
        <StyledTooltipTrigger
          ref={setReferenceElement}
          onClick={handleTriggerClick}
        >
          <StyledTooltipIcon component={Info} />
        </StyledTooltipTrigger>
      </StyledTooltipButton>
      <StyledTooltip
        isVisible={isVisible}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {children}
      </StyledTooltip>
    </>
  )
}

export default Tooltip
