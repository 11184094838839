import { createGlobalStyle } from "styled-components"
import PolestarUnicaRegularWoff from "../assets/fonts/PolestarUnica77TT-Regular.woff"
import PolestarUnicaRegularWoff2 from "../assets/fonts/PolestarUnica77TT-Regular.woff2"
import PolestarUnicaBoldWoff from "../assets/fonts/PolestarUnica77TT-Bold.woff"
import PolestarUnicaBoldWoff2 from "../assets/fonts/PolestarUnica77TT-Bold.woff2"
import PolestarUnicaItalicWoff from "../assets/fonts/PolestarUnica77TT-Italic.woff"
import PolestarUnicaItalicWoff2 from "../assets/fonts/PolestarUnica77TT-Italic.woff2"
import PolestarUnicaLightWoff from "../assets/fonts/PolestarUnica77TT-Light.woff"
import PolestarUnicaLightWoff2 from "../assets/fonts/PolestarUnica77TT-Light.woff2"
import PolestarUnicaMediumWoff from "../assets/fonts/PolestarUnica77TT-Medium.woff"
import PolestarUnicaMediumWoff2 from "../assets/fonts/PolestarUnica77TT-Medium.woff2"

export default createGlobalStyle`
  @font-face {
    font-family: 'Polestar Unica';
    src:
      url(${PolestarUnicaRegularWoff2}) format('woff2'),
      url(${PolestarUnicaRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Polestar Unica';
    src:
      url(${PolestarUnicaBoldWoff2}) format('woff2'),
      url(${PolestarUnicaBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Polestar Unica';
    src:
      url(${PolestarUnicaItalicWoff2}) format('woff2'),
      url(${PolestarUnicaItalicWoff}) format('woff');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Polestar Unica';
    src:
      url(${PolestarUnicaLightWoff2}) format('woff2'),
      url(${PolestarUnicaLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Polestar Unica';
    src:
      url(${PolestarUnicaMediumWoff2}) format('woff2'),
      url(${PolestarUnicaMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
`
