import Description from "app/components/Description"
import InputField from "app/components/Form/Field/Input"
import SelectField from "app/components/Form/Field/Select"
import { useSelector } from "react-redux"
import { getFormFieldData } from "state/form/selectors"
import Heading from "app/components/Heading"
import { useTranslate } from "hooks/translate"
import Body from "app/components/Form/Body"
import { validateAmount, validateMax, validateMin } from "app/validation"
import { Locales } from "i18n"
import { getAppLocale } from "state/app/selectors"
import { parseLinks } from "i18n/utils/parseLinks"
import CheckboxField from "app/components/Form/Field/Checkbox"

const MIN_DURATION = 24
const MAX_DURATION = 60
const DURATION_INTERVAL = 6
const BOTTOM_LIMIT_THRESHOLD_PERCENTAGE = 70

const privacyLinks: {
  [key in Locales]: {
    [key: string]: string
  }
} = {
  [Locales.NL]: {
    link_kbc_privacy_policy: "https://www.kbc.be/particulieren/nl/juridische-info/privacyverklaring.html",
    link_kbc_privacy_annex: "https://multimediafiles.kbcgroup.eu/ng/published/KBC/PDF/kbc-privacyverklaring-annex.pdf",
  },
  [Locales.FR]: {
    link_kbc_privacy_policy: "https://www.kbc.be/particuliers/fr/informations-legales/vie-privee.html",
    link_kbc_privacy_annex: "https://multimediafiles.kbcgroup.eu/ng/published/KBC/PDF/kbc-declaration-vie-privee-annexe.pdf",
  }
}

const Step4: React.FC = () => {
  const { t } = useTranslate()
  const locale = useSelector(getAppLocale)

  const netInvestmentAmountValue = useSelector(
    getFormFieldData("netInvestmentAmount")
  ) as string

  const netInvestmentAmount = parseInt(netInvestmentAmountValue, 10)

  const desiredFinancingAmountMinValue = Math.ceil(
    (netInvestmentAmount / 100) * BOTTOM_LIMIT_THRESHOLD_PERCENTAGE
  )

  const durationOptionsLength =
    Math.min((MAX_DURATION - MIN_DURATION) / DURATION_INTERVAL) + 1
  const durationOptions = Array.from(
    { length: durationOptionsLength },
    (_, i) => {
      const value = MIN_DURATION + DURATION_INTERVAL * i
      return {
        label: String(value),
        value
      }
    }
  )

  return (
    <>
      <Body>
        <Heading>{t("form.step_4.heading")}</Heading>
      </Body>
      <Heading as="h2" variant="strong">
        {t("system.amount")}
      </Heading>
      <Description>{t("form.step_4.description.amount")}</Description>
      <InputField
        name="desiredFinancingAmount"
        label="system.euro"
        labelPlacement="postfix"
        inputMode="numeric"
        description={t("form.field.desiredFinancingAmount.description", {
          min: desiredFinancingAmountMinValue,
          max: netInvestmentAmount
        })}
        rules={{
          required: true,
          validate: {
            amount: validateAmount,
            min: validateMin(desiredFinancingAmountMinValue),
            max: validateMax(netInvestmentAmount)
          }
        }}
      />
      <Heading as="h2" variant="strong">
        {t("system.duration")}
      </Heading>
      <Description>
        {t("form.step_4.description.duration", {
          min: MIN_DURATION,
          max: MAX_DURATION
        })}
      </Description>
      <SelectField
        name="duration"
        label="system.duration"
        labelPlacement="postfix"
        options={durationOptions}
        rules={{ required: true }}
      />
      <CheckboxField
        name="privacyPolicyKbc"
        rules={{ required: true }}
        label={t(
          "system.kbc_privacy_disclaimer",
          parseLinks({
            link_kbc_privacy_annex: {
              target: "_blank",
              href: privacyLinks[locale].link_kbc_privacy_annex
            },
            link_kbc_privacy_policy: {
              target: "_blank",
              href: privacyLinks[locale].link_kbc_privacy_policy
            }
          })
        )}
      />
    </>
  )
}

export default Step4
