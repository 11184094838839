import { StyledFormError } from "./style"

interface FormErrorProps {
  className?: string
}

const FormError: React.FC<FormErrorProps> = ({ children, className }) => {
  return <StyledFormError className={className}>{children}</StyledFormError>
}

export default FormError
