import { ButtonProps } from "app/components/Button"
import { StyledFormButton } from "./style"

export interface FormButtonProps extends ButtonProps {
  action: "prev" | "next"
}

const FormButton: React.FC<FormButtonProps> = props => {
  return <StyledFormButton {...props} />
}

export default FormButton
