import { PropsWithChildren } from "react"
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm
} from "react-hook-form"
import LoadingIndicator from "../LoadingIndicator"
import { StyledForm, StyledFormLoading } from "./style"

type FormProps<T> = PropsWithChildren<{
  onSubmit: SubmitHandler<T>
  defaultValues: DefaultValues<T>
  isPending?: boolean
}>

function Form<T>({
  defaultValues,
  onSubmit,
  isPending,
  children
}: FormProps<T>) {
  const methods = useForm({
    defaultValues
  })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {children}
        {isPending && (
          <StyledFormLoading>
            <LoadingIndicator />
          </StyledFormLoading>
        )}
      </StyledForm>
    </FormProvider>
  )
}

export default Form
