import styled from "styled-components"
import { Icon } from "../Icon"

export const StyledLoadingIcon = styled(Icon)`
  animation: anim-rotate 3.4s linear infinite;

  circle {
    stroke: ${({ theme }) => theme.colors.black};
    stroke-dasharray: 260%;
    stroke-dashoffset: 0;
    transform-origin: 160px 160px;
    animation: anim-stroke-offset 3.4s ease-in-out infinite;
  }

  @keyframes anim-stroke-offset {
    0% {
      stroke-dashoffset: 260%;
    }
    50% {
      stroke-dashoffset: 0%;
      transform: rotate(180deg);
    }
    100% {
      stroke-dashoffset: 260%;
      transform: rotate(725deg);
    }
  }
`
