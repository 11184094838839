import styled from "styled-components"
import Heading from "../Heading"

export const StyledOverView = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray_light};
  margin-bottom: ${({ theme }) => theme.space[4]};
`

export const StyledOverViewSection = styled.div`
  padding: ${({ theme }) => theme.space[3]};

  > :last-child {
    margin-bottom: 0;
  }
`

export const StyledOverViewHeader = styled(StyledOverViewSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.muted};
`

export const StyledOverViewHeading = styled(Heading)`
  &&& {
    margin-bottom: 0;
  }
`

export const StyledOverviewHeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StyledOverViewHeaderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledOverViewAmount = styled.div`
  font-size: 2rem;
  line-height: 1.6;
  font-weight: 700;
`

export const StyledOverViewCaption = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSizes.small};

  @media ${({ theme }) => theme.media.lg_down} {
    text-align: right;
  }
`

export const StyledOverViewBody = styled(StyledOverViewSection)``

export const StyledOverViewFooter = styled(StyledOverViewSection)`
  margin: 0;

  @media ${({ theme }) => theme.media.lg_up} {
    margin: 0 ${({ theme }) => theme.space[3]};
  }
`
