import { forwardRef } from "react"
import { StyledButton, StyledButtonIcon } from "./style"

type DefaultButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "ref"
>

export interface ButtonProps extends DefaultButtonProps {
  variant?: "contained" | "text" | "plain"
  icon?: React.ElementType
  iconPlacement?: "prefix" | "postfix"
  ref?: React.Ref<HTMLButtonElement>
  component?: React.ReactElement
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "contained",
      icon,
      iconPlacement = "prefix",
      component,
      children,
      ...rest
    },
    ref
  ) => {
    const hasIconPrefix = icon && iconPlacement === "prefix"
    const hasIconPostfix = icon && iconPlacement === "postfix"

    return (
      <StyledButton component={component} ref={ref} variant={variant} {...rest}>
        {hasIconPrefix && (
          <StyledButtonIcon iconPlacement={iconPlacement} component={icon} />
        )}
        {children}
        {hasIconPostfix && (
          <StyledButtonIcon iconPlacement={iconPlacement} component={icon} />
        )}
      </StyledButton>
    )
  }
)

export default Button
