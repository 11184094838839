import { CarModels } from "app/enums/carModels.enum"
import { FormSteps } from "app/enums/formSteps.enum"
import { FormStateData, TrackLeadRequestBody } from "state/form/types"
import { getEnvVariable } from "utils"

const interestRates = {
  [CarModels.POLESTAR_2]: getEnvVariable("REACT_APP_SPECIAL_OFFER_POLESTAR_2"),
  [CarModels.POLESTAR_3]: getEnvVariable("REACT_APP_SPECIAL_OFFER_POLESTAR_3"),
  [CarModels.POLESTAR_4]: getEnvVariable("REACT_APP_SPECIAL_OFFER_POLESTAR_4"),
}

export const getTrackLeadPayload = (
  step: number,
  payload: FormStateData
): TrackLeadRequestBody => {
  switch (step) {
    case FormSteps.USER_INFO_STEP:
      return {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        language: payload.language,
        privacyPolicy: payload.privacyPolicy,
        newCar: true,
      }
    case FormSteps.COMPANY_INFO_STEP:
      return {
        enterpriseNumber: payload.enterpriseNumber,
        enterpriseName: payload.enterpriseName
      }
    case FormSteps.INVESTMENT_STEP:
      return {
        carModel: payload.carModel,
        interestRate: interestRates[payload.carModel as CarModels],
        netInvestmentAmount: payload.netInvestmentAmount,
        supplierOrderNumber: payload.supplierOrderNumber
      }
    case FormSteps.DESIRED_FINANCING_STEP:
      return {
        privacyPolicyKbc: payload.privacyPolicyKbc
      }
    case FormSteps.VIEW_PROPOSALS_STEP:
      return {
        journeyCompleted: true
      }
    default:
      return {}
  }
}
