import styled from "styled-components"
import Button from "../Button"
import { Icon } from "../Icon"

export interface StyledTooltipProps {
  isVisible: boolean
}

export const StyledTooltip = styled.div<StyledTooltipProps>`
  z-index: 10;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  max-width: 40rem;
  padding: 1.2rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -1px 1px rgba(0, 0, 0, 0.05);
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.large};
`

export const StyledTooltipButton = styled(Button)`
  align-self: stretch;
  position: relative;
  top: -0.2ex;
`

export const StyledTooltipTrigger = styled.div`
  position: relative;
`

export const StyledTooltipIcon = styled(Icon)`
  display: block;
  color: ${({ theme }) => theme.colors.gray_light};
`
