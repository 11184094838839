import nlTranslations from "./translations/nl.translations.json"
import frTranslations from "./translations/fr.translations.json"

export enum Locales {
  NL = "nl",
  FR = "fr"
}

export const locales = Object.values(Locales)

export const translations = {
  [Locales.NL]: nlTranslations,
  [Locales.FR]: frTranslations
}

export default translations
