import styled, { css } from "styled-components"
import { SpaceProps } from "app/components/Space"

const SPACE_MQ_THRESHOLD = 3

type StyledSpaceTopProps = Pick<SpaceProps, "top">
type StyledSpaceBottomProps = Pick<SpaceProps, "bottom">

const StyledSpaceTopCSS = css<StyledSpaceTopProps>`
  ${({ top }) =>
    top! > SPACE_MQ_THRESHOLD
      ? StyledSpaceTopLargeCSS
      : StyledSpaceTopDefaultCSS}
`

const StyledSpaceTopLargeCSS = css<SpaceProps>`
  margin-top: ${({ theme }) => theme.space[3]};

  @media ${({ theme }) => theme.media.md_up} {
    margin-top: ${({ theme, top }) => theme.space[top!]};
  }
`

const StyledSpaceTopDefaultCSS = css<SpaceProps>`
  margin-top: ${({ theme, top }) => theme.space[top!]};
`

const StyledSpaceTopResetCSS = css<SpaceProps>`
  margin-top: 0;
`

const StyledSpaceBottomCSS = css<StyledSpaceBottomProps>`
  ${({ bottom }) =>
    bottom! > SPACE_MQ_THRESHOLD
      ? StyledSpaceBottomLargeCSS
      : StyledSpaceBottomDefaultCSS}
`

const StyledSpaceBottomLargeCSS = css<SpaceProps>`
  margin-bottom: ${({ theme }) => theme.space[3]};

  @media ${({ theme }) => theme.media.md_up} {
    margin-bottom: ${({ theme, bottom }) => theme.space[bottom!]};
  }
`

const StyledSpaceBottomDefaultCSS = css<SpaceProps>`
  margin-bottom: ${({ theme, bottom }) => theme.space[bottom!]};
`

const StyledSpaceBottomResetCSS = css<SpaceProps>`
  margin-bottom: 0;
`

export const StyledSpace = styled.div<SpaceProps>`
  white-space: pre-line;
  ${({ top }) => (top ? StyledSpaceTopCSS : StyledSpaceTopResetCSS)};
  ${({ bottom }) =>
    bottom ? StyledSpaceBottomCSS : StyledSpaceBottomResetCSS};

  > :last-child {
    margin-bottom: 0;
  }
`
