import { StyledCopyright } from "./style"

type CopyrightProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  "ref"
>

const Copyright: React.FC<CopyrightProps> = ({ children, ...rest }) => {
  return <StyledCopyright {...rest}>{children}</StyledCopyright>
}

export default Copyright
