import { RequestStatus } from "state/types"

export type FormStateValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
  language?: string
  validQuotationDisclaimer: boolean
  informationNotice: boolean
  privacyPolicy?: boolean
  captcha: string
  enterpriseNumber: string
  enterpriseName: string
  activityCategoryCode?: ActivityCategoryCodes
  carModel: string
  netInvestmentAmount: string
  supplierOrderNumber?: string
  desiredFinancingAmount: string
  duration: number
  verificationCode?: number
  privacyPolicyKbc?: boolean
}

export enum ActivityCategoryCodes {
  MEDICAL_PROFESSIONS = "01",
  LIBERAL_PROFESSIONS = "02",
  CONSTRUCTION = "03",
  TRANSPORT_MANUFACTURING = "04",
  CATERING_RETAIL = "05",
  AGRICULTURE = "06",
  MEDIA = "07",
  REAL_ESTATE = "08",
  OTHER_SERVICES = "09",
  SOCIAL_PROFIT_LOCAL_AUTHORITIES = "11"
}

export interface FormStateData extends FormStateValues {
  [key: string]: string | number | undefined | boolean
}

interface ProposalProductData {
  externalRate: string
  oneOffFeeDossierAmount: string
  ownContributionAmount: string
  productTypeDescription: string
  productTypeNumber: string
  residualValueAmount: string
  residualValuePercentage: string
  termAmount: string
  totalRepaymentAmount: string
}
export interface SimulateProposalsResponseData {
  referenceIdentification: string
  isDesiredFinancingAmountOverruled: boolean
  residualValueApplied: boolean
  financingAmount: string
  duration: number
  numberOfProducts: number
  products: ProposalProductData[]
}

export interface SimulateProposalsResponse {
  data: Partial<SimulateProposalsResponseData>
  status: RequestStatus
  errors?: string[]
}

export interface NoDataResponse {
  status: RequestStatus
  errors?: string[]
}

type ExtendedRequestBodyVariables = {
  journeyCompleted: boolean
  interestRate: string
  newCar: boolean
}

export type TrackLeadRequestBody = Partial<
  Pick<FormStateValues, "firstName" |
    "lastName" |
    "email" |
    "phone" |
    "language" |
    "enterpriseNumber" |
    "enterpriseName" |
    "privacyPolicy" |
    "verificationCode" |
    "privacyPolicyKbc" |
    "netInvestmentAmount" |
    "supplierOrderNumber" |
    "carModel"
  > | ExtendedRequestBodyVariables
>

export type AcceptProposalsBody = {
  referenceIdentification: string
  firstName: string
  lastName: string
  language: string
  phone: string
  email: string
  supplierOrderNumber?: string
}

export type VerifyCaptchaRequestBody = {
  token: string
}

export type VerifyLeadRequestBody = {
  verificationCode: number
}

export type VerifyLeadResponseData = {
  isVerified: boolean
}

export type VerifyLeadResponse = {
  data: boolean,
  status: RequestStatus,
  errors?: string[]
}

export interface LeadResponseData {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  language: string
  role: string
  companyName: string | null
  organisationNumber: string | null
  country: string
  leadStatus: string
  type: string
  city: string | null
  street: string | null
  zip: string | null
  source: string
  journeyCompleted: string
  consentGiven: string
  consentGivenAt: string | null
  createdAt: string
  verifiedAt: string | null
}

export interface LeadResponse {
  data: Partial<LeadResponseData>
  status: RequestStatus
  errors?: string[]
}

export interface FormState {
  validatedStep: number
  data: FormStateData
  simulateProposalsResponse: SimulateProposalsResponse
  verifyLeadResponse: VerifyLeadResponse
  acceptProposalsResponse: NoDataResponse
  leadResponse: LeadResponse
  captchaResponse: NoDataResponse
}
