import { components, GroupBase, OptionProps } from "react-select"
import { SelectOptionProps } from "app/components/Select"
import { ReactComponent as Checkmark } from "app/assets/svg/checkmark.svg"
import { StyledOptionIcon } from "./style"

const Option: React.FC<
  OptionProps<SelectOptionProps, false, GroupBase<SelectOptionProps>>
> = ({ children, ...props }) => {
  const { isSelected } = props

  return (
    <components.Option {...props}>
      {children}
      {isSelected && <StyledOptionIcon component={Checkmark} />}
    </components.Option>
  )
}
export default Option
