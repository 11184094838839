import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Locales } from "i18n"
import { getDefaultLocale } from "i18n/utils/getDefaultLocale"
import { AppState } from "./types"

const initialState: AppState = {
  locale: getDefaultLocale()
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Locales>) => {
      state.locale = action.payload
    }
  }
})

export const { setLocale } = appSlice.actions

export default appSlice.reducer
