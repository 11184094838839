import React from "react"
import { Link } from "react-router-dom"
import Button from "app/components/Button"
import Heading from "app/components/Heading"
import { useTranslate } from "hooks/translate"

const NotFound: React.FC = () => {
  const { t } = useTranslate()
  return (
    <>
      <Heading>{t("system.page.not_found")}</Heading>
      <Button component={<Link to="/" />}>{t("system.home")}</Button>
    </>
  )
}

export default NotFound
