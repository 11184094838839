import { parseLinks } from "i18n/utils/parseLinks"
import { useTranslate } from "hooks/translate"
import InputField from "app/components/Form/Field/Input"
import SelectField from "app/components/Form/Field/Select"
import CheckboxField from "app/components/Form/Field/Checkbox"
import Heading from "app/components/Heading"
import Description from "app/components/Description"
import Body from "app/components/Form/Body"
import CaptchaField from "app/components/Form/Field/Captcha"
import { Locales } from "i18n"
import { useSelector } from "react-redux"
import { getAppLocale } from "state/app/selectors"

enum PreferredLanguages {
  NL = "nl",
  FR = "fr",
  DE = "de",
  EN = "en"
}

const legalLinks: {
  [key in Locales]: {
    [key: string]: string
  }
} = {
  [Locales.NL]: {
    link_privacy_policy:
      "https://legal.polestar.com/nl-be/privacy/f2bfd98d7c84abc5c0a801514bc82f50/",
    link_info_notice:
      "https://legal.polestar.com/nl-be/privacy/a14484e62327aa95c0a8015172fdc42b/",
    link_privacy: "https://legal.polestar.com/nl-be/privacy/"
  },
  [Locales.FR]: {
    link_privacy_policy:
      "https://legal.polestar.com/fr-be/privacy/f2bfd98d7c84abc5c0a801514bc82f50/",
    link_info_notice:
      "https://legal.polestar.com/fr-be/privacy/a14484e62327aa95c0a8015172fdc42b/",
    link_privacy: "https://legal.polestar.com/fr-be/privacy/"
  }
}

const Step1: React.FC = () => {
  const { t } = useTranslate()
  const locale = useSelector(getAppLocale)

  const preferredLanguageOptions = Object.values(PreferredLanguages).map(
    locale => ({
      label: t(`system.locale.${locale}`),
      value: locale
    })
  )

  return (
    <>
      <Body>
        <Heading>{t("form.step_1.heading")}</Heading>
        <Description>{t("form.step_1.body")}</Description>
      </Body>
      <InputField
        name="firstName"
        label="system.first_name"
        rules={{ required: true }}
      />
      <InputField
        name="lastName"
        label="system.last_name"
        rules={{ required: true }}
      />
      <InputField
        name="email"
        label="system.email"
        inputMode="email"
        rules={{
          required: true,
          pattern: {
            value: /^\S+@\S+\.\S+$/i,
            message: t("error.email")
          }
        }}
      />
      <InputField
        name="phone"
        label="system.phone"
        description={t("form.field.phone.description")}
        type="tel"
        rules={{
          required: true,
          pattern: {
            value: /^\+32[^0][0-9]{6,}$/,
            message: t("error.phone")
          }
        }}
      />
      <SelectField
        name="language"
        label="form.field.preferred_language.label"
        options={preferredLanguageOptions}
        rules={{ required: true }}
      />
      <CheckboxField
        name="validQuotationDisclaimer"
        label={t("system.legal.valid_quotation", {
          br: <br />
        })}
        rules={{ required: true }}
      />
      <CheckboxField
        name="informationNotice"
        label={t(
          "form.field.information_notice.label",
          parseLinks({
            link_info_notice: {
              target: "_blank",
              href: legalLinks[locale].link_info_notice
            }
          })
        )}
        rules={{ required: true }}
      />
      <CheckboxField
        name="privacyPolicy"
        label={t(
          "form.field.privacy_policy.label",
          parseLinks({
            link_privacy_policy: {
              target: "_blank",
              href: legalLinks[locale].link_privacy_policy
            },
            link_info_notice: {
              target: "_blank",
              href: legalLinks[locale].link_info_notice
            },
            link_privacy: {
              target: "_blank",
              href: legalLinks[locale].link_privacy
            }
          })
        )}
      />
      {locale === Locales.NL && (
        <CaptchaField
          lang={Locales.NL}
          name="captcha"
          rules={{ required: true }}
        />
      )}
      {locale === Locales.FR && (
        <CaptchaField
          lang={Locales.FR}
          name="captcha"
          rules={{ required: true }}
        />
      )}
    </>
  )
}

export default Step1
