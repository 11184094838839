import { StyledFormMessage } from "./style"

interface FormMessageProps {
  className?: string
}

const FormMessage: React.FC<FormMessageProps> = ({ children, className }) => {
  return <StyledFormMessage className={className}>{children}</StyledFormMessage>
}

export default FormMessage
