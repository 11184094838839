import { Locales, locales } from "i18n"
import { useDispatch, useSelector } from "react-redux"
import { getAppLocale } from "state/app/selectors"
import { setLocale } from "state/app/slice"
import { SelectProps } from "../Select"
import { StyledLanguageSwitch } from "./style"

const localeOptions = locales.map(locale => ({
  label: locale,
  value: locale
}))

export const LanguageSwitch: React.FC = () => {
  const appLocale = useSelector(getAppLocale)
  const dispatch = useDispatch()

  const handleLocaleChange: SelectProps["onChange"] = option => {
    const value = option?.value
    if (!value) return
    dispatch(setLocale(value as Locales))
  }

  return (
    <StyledLanguageSwitch
      id="language_select"
      options={localeOptions}
      onChange={handleLocaleChange}
      value={appLocale}
      align="right"
    />
  )
}

export default LanguageSwitch
