import styled, { css } from "styled-components"
import { HeadingProps, HeadingTypes } from "app/components/Heading"

export type StyledHeadingProps = Pick<HeadingProps, "variant"> & {
  as: HeadingTypes
}

const HeadingDefaultCSS = css`
  font-weight: 400;
`

const HeadingItalicCSS = css`
  font-style: italic;
  font-weight: 400;
`

const HeadingStrongCSS = css`
  font-weight: 700;
`

export const StyledHeading = styled.h1<StyledHeadingProps>`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme, as }) => theme.fontSizes[as]};
  line-height: ${({ theme }) => theme.lineHeights.heading};
  ${({ variant }) => {
    switch (variant) {
      case "strong":
        return HeadingStrongCSS
      case "italic":
        return HeadingItalicCSS
      default:
        return HeadingDefaultCSS
    }
  }};
`
