import styled from "styled-components"
import checkbox from "app/assets/svg/checkbox.svg"

export const StyledFieldContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space[3]};
`

export const StyledLabel = styled.label`
  white-space: pre-line;
  --radio-border-color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray_dark};

  &:hover {
    --radio-border-color: ${({ theme }) => theme.colors.gray};
  }
`

export const StyledInput = styled.input`
  transition: border-color 200ms ease, background-color 200ms ease;
  appearance: none;
  vertical-align: middle;
  transform: translateY(-0.1ex);
  width: 2.2rem;
  height: 2.2rem;
  margin-right: ${({ theme }) => theme.space[2]};
  border: 1px solid var(--radio-border-color);

  &:checked {
    --radio-border-color: ${({ theme }) => theme.colors.primary};
    background: url(${checkbox}) center center no-repeat
      ${({ theme }) => theme.colors.primary};
  }
`
