import Button from "app/components/Button"
import styled from "styled-components"

export const StyledButtonWrapper = styled.div`
  margin-top: -5rem;
`

export const StyledButton = styled(Button)`
  margin-right: 1rem;
`
